@layer components {
  .app-anim-expander {
    @apply transition-transform duration-500;

    &.expanded {
      @apply rotate-180;
    }
  }

  .app-anim-focus:hover {
    @apply border border-solid border-blue-500;
  }

  .app-animate-all--ease {
    @apply transition-all duration-200 ease-in-out;
  }
}
