@use '../tokens/typos.scss';
@use '../tokens/colors.scss';
@use '../tokens/textures.scss';

* {
  box-sizing: border-box;
  font-family: typos.$font-family-primary;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  font-size: typos.$typo-body;
  font-weight: textures.$weight-normal;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: colors.$color_background;
}

.p-multiselect-token-label {
  max-width: 300px;
}

.pi {
  font-family: typos.$font-family-primary;
}

.pi::before {
  font-family: 'primeicons', sans-serif;
}

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilites {
  @tailwind components;
  @tailwind utilities;
}
