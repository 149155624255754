@use 'foundations/base.scss';
@use 'foundations/anim.scss';
@use 'foundations/grid.scss';
@use 'foundations/shadows.scss';
@use 'foundations/typography.scss';
//@import './foundations/colors.scss';
@use 'atoms/icons.scss';

@layer primeng {
  .p-timeline-event-content {
    padding-bottom: 1rem !important;
  }
}
