/*tomado de https://adn.sura.com/manual-de-marca*/

$color_white: #ffffff;

// paleta de seguros sura
$color_aqua_700: #00aec7;
$color_aqua_200: #9be1e9;
$color_aqua_100: #d5f5f8;
$color_blue_800: #0033a0;
$color_blue_500: #2d6df6;
$color_blue_400: #8a9cd3;
$color_blue_200: #81b1ff;
$color_blue_100: #e5e9ea;
$color_blue_50: #dfeaff;
$color_gray_800: #888b8d;
$color_gray_200: #b4b4b5;
$color_gray_100: #f8f8f8;
$color_yellow_800: #e3e829;
$color_yellow_200: #ecf0a1;
$color_yellow_100: #f9fae1;

$color_primary: $color_blue_500;
$color_secondary: $color_aqua_700;
$color_background: $color_white;
$color_font_primary: $color_gray_200;
$color_font_secondary: $color_white;
