@use '../tokens/colors.scss';

.app-icon-primary {
  color: colors.$color_primary;
}

.app-icon-secondary {
  color: colors.$color_secondary;
}

.app-icon-clickable {
  cursor: pointer;
}
