$typo-h1: 3.75rem;
$typo-h2: 3rem;
$typo-h3: 2.5rem;
$typo-body: 1rem;
$typo-button: 0.875rem;

/*Fonts*/
$font-family-primary: 'Sura Sans', sans-serif;

@font-face {
  font-family: 'Sura Sans';
  src: url('/assets/fonts/SuraSans/SuraSans-Regular.otf') format('opentype');
}
